<template>
  <div v-if="this.brand">
    <el-row :gutter="20">
      <el-col :span="16">
        <!-- <custom-header :title="this.brand.brand_name_mon + '- Үнэ батлуулах хуудас'" /> -->
        <header class="page-header">
          <el-row :gutter="20" align="middle" type="flex">
            <el-col :span="12">
              <el-button type="default" @click="goBack" class="mr20"><i class="el-icon-back"></i></el-button>
              <h2 class="inline-block">{{this.brand.brand_name_mon + ' - Үнэ батлуулах хуудас'}}</h2>
            </el-col>
          </el-row>
        </header>
      </el-col>
      <el-col :span="8" class="text-right">
        <el-button v-if="this.$route.params.status === 'pending'" type="primary" @click="isDialogVisible('accepted')">Батлах</el-button>
        <el-button v-if="this.$route.params.status === 'pending'" type="danger" @click="isDialogVisible('cancel')">Цуцлах</el-button>
        <el-button type="info" @click="Generate">Хэвлэх</el-button>
        <vue-excel-xlsx
          style="border: none"
          :data="excel"
          :columns="generateExcelData()"
          :file-name="'Үнэ батлуулах хуудас'"
          :file-type="'xlsx'"
          :sheet-name="this.status === 'pending' ? 'Хүлээгдэж буй' : this.status === 'accepted' ? 'Баталгаажсан' : 'Цуцалсан'"
          >
          <el-button type="success" icon="el-icon-download" size="small">Татаж авах (.xlsx)</el-button>
        </vue-excel-xlsx>
      </el-col>
    </el-row>
    <div class="panel tabled">
      <div class="panel-content">
        <div class="pl20 pr20 table-filter mb10">
          <el-table
          size="small"
          v-loading="loading"
          :data="brandData"
          style="width: 100%"
          class="mt20">
            <el-table-column label="№" type="index" :index="indexMethod" />
            <el-table-column v-if="this.$route.params.status !== 'pending'" label="Дугаар" width="80">
              <template slot-scope="scope">
                <span>{{scope.row.price_number === null ? 0 : scope.row.price_number}}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="product_name"
              label="Барааны нэр">
            </el-table-column>
            <el-table-column
              prop="variant_name"
              label="Хувилбарын нэр">
            </el-table-column>
            <el-table-column label="НӨАТ-тай дүн" align="center">
              <el-table-column label="ББӨ" align="center" v-if="this.$route.params.status === 'pending'">
                <template slot-scope="scope">
                  <el-input :disabled="scope.row.editType" v-model="scope.row.get_price"></el-input>
                </template>
              </el-table-column>
              <el-table-column v-else label="ББӨ" align="center">
                <template slot-scope="scope">{{generateCurrency(scope.row.get_price)}}</template>
              </el-table-column>
              <el-table-column label="Худалдах үнэ" align="center" v-if="this.$route.params.status === 'pending'">
                <template slot-scope="scope">
                  <el-input :disabled="scope.row.editType" v-model="scope.row.sell_price"></el-input>
                </template>
              </el-table-column>
              <el-table-column v-else label="Худалдах үнэ" align="center">
                <template slot-scope="scope">{{generateCurrency(scope.row.sell_price)}}</template>
              </el-table-column>
              <el-table-column label="Хямдралтай үнэ" align="center" v-if="this.$route.params.status === 'pending'">
                <template slot-scope="scope">
                  <el-input :disabled="scope.row.editType" v-model="scope.row.discounted_price"></el-input>
                </template>
              </el-table-column>
              <el-table-column label="Хямдралтай ББӨ" align="center" v-if="this.$route.params.status === 'pending'">
                <template slot-scope="scope">
                  <el-input :disabled="scope.row.editType" v-model="scope.row.get_discounted_price"></el-input>
                </template>
              </el-table-column>
              <el-table-column v-else label="Хямдралтай үнэ" align="center">
                <template slot-scope="scope">{{generateCurrency(scope.row.discounted_price)}}</template>
              </el-table-column>
            </el-table-column>
            <el-table-column label="НӨАТ-гүй дүн" align="center">
              <el-table-column label="ББӨ" align="center">
                <template slot-scope="scope">{{generateCurrency(scope.row.get_price_without_tax)}}</template>
              </el-table-column>
              <el-table-column label="Худалдах үнэ" align="center">
                <template slot-scope="scope">{{generateCurrency(scope.row.sell_price_witout_tax)}}</template>
              </el-table-column>
              <el-table-column label="Хямдралтай ББӨ" align="center">
                <template slot-scope="scope">{{generateCurrency(scope.row.discount.get_price_without_tax)}}</template>
              </el-table-column>
              <el-table-column label="Хямдралтай зарах үнэ" align="center">
                <template slot-scope="scope">{{generateCurrency(scope.row.discount.sell_price_witout_tax)}}</template>
              </el-table-column>
              <el-table-column label="Бохир ашиг" align="center">
                <template slot-scope="scope">{{generateCurrency(scope.row.profit)}}</template>
              </el-table-column>
              <el-table-column label="Бохир ашгийн маржин" align="center">
                <template slot-scope="scope">
                  <span>{{scope.row.profit_percent}}%</span>
                </template>
              </el-table-column>
            </el-table-column>
            <el-table-column label="Хямдралтай" align="center">
              <el-table-column label="Бохир ашиг">
                <template slot-scope="scope">{{scope.row.discount.profit}}</template>
              </el-table-column>
              <el-table-column label="Бохир ашгийн маржин">
                <template slot-scope="scope">{{Object.prototype.hasOwnProperty.call(scope.row.discount, 'profit_percent') ? scope.row.discount.profit_percent + '%' : ''}}</template>
              </el-table-column>
            </el-table-column>
            <el-table-column v-if="this.$route.params.status !== 'pending'"
              prop="accepted_at"
              :label="this.$route.params.status === 'accepted' ? 'Баталгаажсан өдөр' : 'Цуцалсан өдөр'">
            </el-table-column>
            <el-table-column label="Оруулсан хүн" prop="employee"></el-table-column>
            <el-table-column v-if="this.$route.params.status !== 'pending'" :label="this.$route.params.status === 'accepted' ? 'Баталсан хүн' : this.$route.params.status === 'pending' ? 'Батлах, цуцлах хүн' : 'Цуцалсан хүн'" align="center">
              <template slot-scope="scope">
                <span>{{scope.row.accepted_employee !== null ? scope.row.accepted_employee : '-'}}</span>
              </template>
            </el-table-column>
            <el-table-column label="Засах" v-if="this.$route.params.status === 'pending'" align="center">
              <template slot-scope="scope">
                <el-button v-if="scope.row.editType" @click="editPrice(scope.row)" type="primary" icon="el-icon-edit" plain></el-button>
                <el-button v-else @click="updatePrice(scope.row)" type="success" plain>Өөрчлөх</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <el-pagination
        @current-change = "curentPageHandler"
        @size-change = "sizeChangeHandler"
        class="text-right mt10"
        background
        :current-page.sync="currentPage"
        :page-size="pageSize"
        :page-sizes="[5, 20, 50, 100]"
        layout="total, sizes, prev, pager, next"
        :total="total">
      </el-pagination>
    </div>
    <el-dialog
      :title="this.type === 'accepted' ? 'Үнэ батлах' : 'Үнэ цуцлах'"
      :visible.sync="dialogVisible"
      width="30%"
      center>
      <span>Та <strong>{{this.brand.supplier_name_mon}}</strong> нийлүүлэгчийн барааны үнэ {{this.type === 'accepted' ? 'батлахдаа' : 'цуцлахдаа'}} итгэлтэй байна уу?</span>
      <el-input v-if="this.type === 'cancel'" class="mt20" type="textarea" placeholder="Шалтгаан, тайлбар" v-model="desc"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">Буцах</el-button>
        <el-button :type="this.type === 'accepted' ? 'primary' : 'danger'" @click="acceptAndCancelAcceptedPrice">{{this.type === 'accepted' ? 'Батлах' : 'Цуцлах'}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import services from '../../../helpers/services'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import { getName } from '../../../utils/auth'
import helper from '../../../helpers/helper'
pdfMake.vfs = pdfFonts.pdfMake.vfs
export default {
  name: 'supplierDetail',
  async created () {
    this.brand = this.$route.params.data
    this.userName = await getName()
    this.getAcceptedPriceBySupplierId(this.brand.brand_id, this.pageSize * (this.currentPage - 1), this.pageSize)
    this.excelData()
  },
  data () {
    return {
      excel: [],
      updateData: null,
      onePriceData: {},
      status: this.$route.params.status,
      userName: '',
      brandData: [],
      brand: null,
      loading: false,
      total: 0,
      dialogVisible: false,
      type: '',
      desc: '',
      currentPage: 1,
      pageSize: 20
    }
  },
  methods: {
    generateDate () {
      let tempData = ''
      if (this.brandData.length > 0 && this.$route.params.status !== 'pending') {
        tempData = this.brandData[0].accepted_at
      } else if (this.$route.params.status === 'pending') {
        tempData = new Date((new Date()).toString().split('GMT')[0] + ' UTC').toISOString().slice(0, 19).replace('T', ' ')
      } else {
        tempData = ''
      }
      return tempData
    },
    generateCurrency (val) {
      return helper.generateCurrency(parseFloat(val))
    },
    goBack () {
      this.$router.push({
        name: 'supplierList'
      })
    },
    generateExcelData  () {
      const tempData = [
        {
          label: 'Барааны нэр',
          field: 'product_name'
        },
        {
          label: 'Хувилбарын нэр',
          field: 'variant_name'
        },
        {
          label: 'НӨАТ-тэй ББӨ',
          field: 'get_price',
          dataFormat: this.priceFormat
        },
        {
          label: 'НӨАТ-тэй худалдах үнэ',
          field: 'sell_price'
        },
        {
          label: 'НӨАТ-тэй хямдралтай үнэ',
          field: 'get_discounted_price'
        },
        {
          label: 'НӨАТ-тэй хямдралтай ББӨ',
          field: 'discounted_price'
        },
        {
          label: 'НӨАТ-гүй ББӨ',
          field: 'get_price_without_tax'
        },
        {
          label: 'НӨАТ-гүй худалдах үнэ',
          field: 'sell_price_witout_tax'
        },
        {
          label: 'НӨАТ-гүй хямдралтай ББӨ',
          field: 'get_price_without_tax'
        },
        {
          label: 'НӨАТ-гүй хямдралтай зарах үнэ',
          field: 'sell_price_witout_tax'
        },
        {
          label: 'НӨАТ-гүй бохир ашиг',
          field: 'profit'
        },
        {
          label: 'НӨАТ-гүй бохир ашгийн маржин',
          field: 'profit_percent',
          dataFormat: this.percentFormat
        },
        {
          label: 'Оруулсан хүн',
          field: 'employee'
        },
        {
          label: 'Хямдралтай бохир ашиг',
          field: 'discount.profit'
        },
        {
          label: 'Хямдралтай бохир ашгийн маржин',
          field: 'discount.profit_percent'
        }
      ]
      if (tempData) {
        tempData.push(
          {
            label: this.status === 'accepted' ? 'Баталгаажсан өдөр' : this.status === 'cancel' ? 'Цуцалсан өдөр' : '',
            field: this.status === 'accepted' || this.status === 'cancel' ? 'accepted_at' : 'Хугацаа оруулаагүй'
          },
          {
            label: this.status === 'accepted' ? 'Баталсан хүн' : this.status === 'cancel' ? 'Цуцалсан хүн' : '',
            field: this.status === 'accepted' || this.status === 'cancel' ? 'accepted_employee' : 'Нэр оруулаагүй'
          })
      }
      return tempData
    },
    priceFormat (value) {
      return value + '₮'
    },
    percentFormat (value) {
      return value + '%'
    },
    updatePrice (data) {
      const body = {
        id: data.id,
        brand_id: data.brand_id,
        product_id: data.product_id,
        variant_id: data.variant_id,
        get_price: parseInt(data.get_price),
        sell_price: parseInt(data.sell_price),
        employee: data.employee,
        discounted_price: data.discounted_price === '' ? 0 : parseInt(data.discounted_price),
        get_discounted_price: data.get_discounted_price === '' ? 0 : parseInt(data.get_discounted_price),
        // eslint-disable-next-line no-unneeded-ternary
        is_discount: parseInt(data.discounted_price) === 0 ? false : data.discounted_price === '' ? false : true,
        is_accepted: data.is_accepted,
        destroyTime: data.destroyTime,
        product_name: data.product_name,
        product_image: data.product_image,
        variant_name: data.variant_name,
        variant_image: data.variant_image,
        supplier_name: data.supplier_name
      }
      services.acceptedPrice(body).then(response => {
        if (response.status === 'success') {
          data.editType = true
          this.alertReporter('Амжилттай', response.message, 'success')
          this.brandData = []
          this.getAcceptedPriceBySupplierId(this.brand.brand_id, this.pageSize * (this.currentPage - 1), this.pageSize)
        } else {
          data.editType = true
          this.alertReporter('Уучлаарай', response.message, 'error')
          this.brandData = []
          this.getAcceptedPriceBySupplierId(this.brand.brand_id, this.pageSize * (this.currentPage - 1), this.pageSize)
        }
      })
    },

    editPrice (data) {
      this.onePriceData = data
      this.brandData.forEach(element => {
        if (element.id === data.id) {
          element.editType = false
        } else {
          element.editType = true
        }
      })
    },

    sizeChangeHandler (item) {
      this.pageSize = item
      this.$router.push({ name: 'supplierDetail', query: { page: item, size: this.pageSize }, params: { status: this.status } }).catch(() => {})
      this.getAcceptedPriceBySupplierId(this.brand.brand_id, this.pageSize * (this.currentPage - 1), this.pageSize)
    },

    curentPageHandler (item) {
      this.brandData = []
      this.currentPage = item
      this.$router.push({ name: 'supplierDetail', query: { page: item, size: this.pageSize }, params: { status: this.status } }).catch(() => {})
      this.getAcceptedPriceBySupplierId(this.brand.brand_id, this.pageSize * (this.currentPage - 1), this.pageSize)
    },

    isDialogVisible (type) {
      this.dialogVisible = true
      this.type = type
    },

    acceptAndCancelAcceptedPrice () {
      const body = {
        brand_id: this.brand.brand_id,
        is_accepted: this.type,
        desc: this.desc
      }
      services.acceptAndCancelAcceptedPrice(body).then(response => {
        if (response.status === 'success') {
          this.dialogVisible = false
          this.alertReporter('Амжилттай', response.message, 'success')
          this.$router.push({
            name: 'supplierList'
          }).catch(() => {})
        } else {
          this.alertReporter('Уучлаарай', response.message, 'error')
        }
      })
    },
    indexMethod (index) {
      return index + 1
    },

    excelData () {
      const query = '/getAcceptedPriceByBrandId?brand_id=' + this.brand.brand_id + '&type=' + this.status + '&from=' + 0 + '&size=' + 1000
      services.statusPriceSupplier(query).then(response => {
        const tempData = response.data
        if (response.status === 'success') {
          tempData.forEach(element => {
            element.editType = true
            this.excel.push(element)
          })
        }
      })
    },

    async getAcceptedPriceBySupplierId (id, from, size) {
      this.loading = true
      const query = '/getAcceptedPriceByBrandId?brand_id=' + id + '&type=' + this.status + '&from=' + from + '&size=' + size
      await services.statusPriceSupplier(query).then(response => {
        const tempData = response.data
        if (response.status === 'success') {
          tempData.forEach(element => {
            element.editType = true
            this.brandData.push(element)
          })
          this.total = response.count
          this.loading = false
        }
      })
    },
    generateTaxPaid () {
      const tempData = this.brand.is_tax_paid === true ? 'Тийм' : 'Үгүй'
      return tempData
    },
    generateFeePercenet () {
      const tempData = this.brandData[0].brand.fee_percent ? this.brandData[0].brand.fee_percent + '%' : '........'
      return tempData
    },
    Generate () {
      var docDefinition = {
        content: [
          { style: 'header', text: 'Батлав: Гүйцэтгэх захирал..................../Ч.Долгорсүрэн/', alignment: 'center' },
          { style: 'subheader', text: 'ҮНЭ БАТЛУУЛАХ ХУУДАС', alignment: 'center' },
          {
            columns: [
              {
                style: 'otherStyle',
                width: '50%',
                text: 'Компаний нэр: ' + this.brandData[0].supplier.supplier_monName
              }, {
                style: 'otherStyle',
                width: '50%',
                text: 'Хөнгөлөлтийн хувь: ' + this.generateFeePercenet()
              }
            ],
            columnGap: 10
          },
          {
            columns: [
              {
                style: 'otherStyle',
                width: '50%',
                text: 'НӨАТ төлөгч эсэх: ' + this.generateTaxPaid()
              }, {
                style: 'otherStyle',
                width: '50%',
                text: 'Зориулалт: ........................'
              }
            ],
            columnGap: 10
          },
          {
            columns: [
              {
                style: 'otherStyle',
                width: '50%',
                text: 'Брэндийн нэр: ' + this.brand.brand_name_mon
              }
            ],
            columnGap: 10
          },
          { style: 'otherStyle', alignment: 'right', text: this.generateDate() },
          ('\n'),
          {
            style: 'tableExample',
            table: {
              body: [
                [{ text: 'Барааны нэр', style: 'tableHeader' }, { text: 'Хувилбарын нэр', style: 'tableHeader' }, { text: 'НӨАТ-тай ББӨ', style: 'tableHeader' }, { text: 'НӨАТ-тай худалдах үнэ', style: 'tableHeader' }, { text: 'НӨАТ-гүй ББӨ', style: 'tableHeader' }, { text: 'НӨАТ-гүй худалдах үнэ', style: 'tableHeader' }, { text: 'НӨАТ-гүй бохир ашиг', style: 'tableHeader' }, { text: 'НӨАТ-гүй бохир ашгийн маржин', style: 'tableHeader' }]
              ]
            }
          },
          ('\n'),
          { style: 'otherStyle', text: 'Хянасан: Санхүүгийн менежер:...................... О.Саруултуяа' },
          { style: 'otherStyle', text: 'Боловсруулсан: PD Веб админ:....................... ' + (this.userName) }
        ],
        styles: {
          otherStyle: {
            fontSize: 8
          },
          header: {
            fontSize: 9,
            margin: [0, 0, 0, 5]
          },
          title: {
            fontSize: 12,
            bold: true,
            margin: [-10, 0, 0, 10]
          },
          subheader: {
            fontSize: 10,
            bold: true,
            margin: [0, 5, 0, 5]
          },
          tableExample: {
            fontSize: 8,
            margin: [0, 0, 0, 0]
          },
          tableHeader: {
            bold: true,
            fontSize: 8,
            color: 'black'
          },
          status: {
            fontSize: 8,
            bold: true,
            margin: [0, 0, 5, 15]
          }
        }
      }
      if (this.excel.length > 0) {
        this.excel.forEach(variant => {
          docDefinition.content[7].table.body.push([{ text: variant.product_name }, { text: variant.variant_name }, { text: variant.get_price ? this.generateCurrency(variant.get_price).replace('₮', '') : '' }, { text: variant.sell_price ? this.generateCurrency(variant.sell_price).replace('₮', '') : '' }, { text: variant.get_price_without_tax ? this.generateCurrency(variant.get_price_without_tax).replace('₮', '') : '' }, { text: variant.sell_price_witout_tax ? this.generateCurrency(variant.sell_price_witout_tax).replace('₮', '') : '' }, { text: variant.profit ? this.generateCurrency(variant.profit).replace('₮', '') : '' }, { text: variant.profit_percent + '%' }])
        })
        pdfMake.createPdf(docDefinition).print()
      } else {
        this.alertReporter('Уучлаарай', 'Бүтээгдэхүүнээ сонгоно уу!', 'error')
      }
    },

    alertReporter (title, message, type) {
      this.$notify({
        title: title,
        message: message,
        type: type,
        position: 'bottom-left'
      })
    }
  }
}
</script>
